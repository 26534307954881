// src/Hero.js
import React from 'react';
import './HomePage.css'; // Import the new stylesheet

export default function Hero() {
  return (
    <div className='hero'>
      <h1>Welcome to Quiz Arena</h1>
      <p>Engage in exciting quizzes and challenge your knowledge!</p>
      <a
        href='https://apps.apple.com/us/app/quiz-arena/id6474947179?platform=iphone'
        target='_blank'
        rel='noreferrer'
      >
        <button className='hero-cta'>Start Playing</button>
      </a>
    </div>
  );
}
