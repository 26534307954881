// src/Contact.js
import React, { useState } from 'react';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to an API
    console.log('Form data:', formData);
  };

  return (
    <div
      style={{
        paddingTop: '50px',
      }}
    >
      <div className='contact-container'>
        <h1>Contact Us</h1>
        <p>
          Welcome to QuizArena Contact Page, the ultimate platform for quiz enthusiasts! Engage in a
          wide range of quizzes, challenge friends, and climb the global leaderboards. Whether
          you're a trivia novice or a quiz master, QuizArena offers an exciting and competitive
          experience.
        </p>

        <h2>Get in Touch</h2>
        <p>
          Have questions, feedback, or need support? Fill out the form below and we'll get back to
          you soon.
        </p>

        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label>Name:</label>
            <input type='text' name='name' value={formData.name} onChange={handleChange} />
          </div>
          <div className='form-group'>
            <label>Email:</label>
            <input type='email' name='email' value={formData.email} onChange={handleChange} />
          </div>
          <div className='form-group'>
            <label>Message:</label>
            <textarea name='message' value={formData.message} onChange={handleChange}></textarea>
          </div>
          <button
            type='submit'
            onClick={() => {
              // email me
              window.open('mailto:niravpatelp129@gmail.com');
            }}
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
}
