// src/App.js
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import ContactPage from './ContactPage';
import DeepLinkHandler from './DeepLinkHandler/index.js';
import HomePage from './HomePage';
import InvitePage from './InvitePage.js';
import Navbar from './Navbar';
import PrivacyPage from './PrivacyPage';

function App() {
  return (
    <Router>
      <DeepLinkHandler />
      <Navbar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/invite' element={<InvitePage />} />

        <Route path='/contact' element={<ContactPage />} />
        <Route path='/privacy' element={<PrivacyPage />} />
      </Routes>
    </Router>
  );
}

export default App;
