import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div style={{ paddingTop: '50px' }}>
      <div className='privacy-container'>
        <h1>Privacy Policy for QuizArena</h1>
        <p>Last updated: January 19, 2024</p>

        <h2>Introduction</h2>
        <p>
          Welcome to QuizArena! As a mobile application inspired by the popular quiz app QuizUp, we
          offer a variety of quizzes across multiple topics for entertainment and educational
          purposes. We value your privacy and are committed to protecting it. This Privacy Policy
          outlines our practices regarding the handling of any personal data we might access.
        </p>

        <h2>Collection of Contact List Information</h2>
        <p>
          QuizArena collects and uploads users' Contact List information to our server at
          https://quiz-arena-backend-96d07772fd89.herokuapp.com. This data is used to enhance the
          user experience by providing personalized quiz recommendations and social features. We
          access this information only with your explicit consent and do not store it on our
          servers.
        </p>

        <h2>Leaderboard Display</h2>
        <p>
          At QuizArena, we display the top scores of our users on leaderboards to foster a sense of
          community and friendly competition. Please note that leaderboard listings are based on
          usernames only, without revealing any personal information. If you prefer not to be listed
          on the leaderboard, you can choose to play in an anonymous mode or contact us for further
          assistance.
        </p>

        <h2>Use of Logos and Images</h2>
        <p>
          In our quizzes, we use a variety of logos and images that are not owned by QuizArena.
          These logos and images are utilized for educational and entertainment purposes under the
          principle of fair use. We do not claim any ownership of these materials and acknowledge
          their respective copyright holders. If there are any concerns or claims regarding the use
          of these images, please contact us at the provided contact information.
        </p>

        <p>
          All logos shown or represented in this game are copyrighted and/or trademarks of their
          respective corporations. The use of low-resolution logo images in this trivia app for
          identification in an informational context qualifies as fair use under copyright law.
          Furthermore, the use of these logos does not misrepresent the companies in any way, shape,
          or form.
        </p>

        <h2>Compliance with Laws</h2>
        <p>
          We strictly adhere to all legal standards and ensure that our app remains compliant with
          data protection laws. We are committed to ensuring the privacy and data security of our
          users while using QuizArena.
        </p>

        <p>
          By posting User Content to the Services, you grant Quiz Arena a non-exclusive,
          transferable, sublicenseable, worldwide, royalty-free right and license to use, modify,
          publicly perform, publicly display, reproduce, and distribute such User Content on and
          through the Services.
        </p>

        <p>
          You represent and warrant that: (i) the User Content is yours (you own it) or you have the
          right to use it and grant us the rights and license as provided in these Terms, and (ii)
          the posting of the User Content on or through the Services does not violate the privacy
          rights, publicity rights, copyrights, contract rights or any other rights of any person.
        </p>

        <p>
          You are responsible for the Topics you create, including their legality, reliability, and
          appropriateness. You represent and warrant that the user content is yours or you have the
          right to use it and grant us the right and license and that you are not violating any
          privacy rights, publicity rights, copyrights, contract rights or any other rights of any
          person. Do not upload any images that the copyright holder disagrees with, e.g. images
          identified with a copyright notice. You are responsible for all uploaded images and
          material. We have the right to decide at our sole discretion that the material you have
          created is inappropriate and therefore not allowed and removed from the app.
        </p>

        <h2>Children's Privacy</h2>
        <p>
          QuizArena is committed to protecting the privacy of children. We do not knowingly collect
          personal data from children under the age of 13. If we become aware that we have
          inadvertently received personal data from a child under the age of 13, we will delete such
          information from our records.
        </p>

        <h2>Privacy Compliance in Question Submission</h2>
        <p>
          At QuizArena, we respect and adhere to privacy laws and regulations, including those
          established by companies like Apple. When you submit questions to our platform, you must
          ensure that they comply with these privacy standards. This includes, but is not limited
          to, avoiding questions that could lead to the disclosure of personal information or
          infringe upon the privacy rights of individuals.
        </p>
        <p>
          We reserve the right to review and remove any questions that do not meet these privacy
          standards. It's our commitment to ensure that QuizArena remains a safe and respectful
          platform for all users. If you have any doubts or questions about the appropriateness of
          your content, please feel free to reach out to us.
        </p>

        <h2>Collection of Contact List Information</h2>
        <p>
          To enhance your experience on QuizArena and facilitate easier connection with friends, our
          app offers the feature to sync with your phone’s contact list. This feature is completely
          optional. If you choose to use it, we will access your contact list to identify and
          suggest potential connections on our platform.
        </p>
        <p>
          We understand the importance of privacy and assure you that the contact information is
          accessed only with your explicit consent and is used solely for the purpose of enhancing
          user connectivity within the app. We do not store your contacts on our servers and do not
          use this information for any other purposes.
        </p>
        <p>
          You have full control over this feature and can opt-in or opt-out at any time through the
          app settings. The safety and privacy of your data are paramount to us, and we are
          committed to ensuring the highest standards of data protection.
        </p>

        <h2>Disclosure for Play Console</h2>
        <p>
          For the purposes of meeting Google Play Store's requirements, we explicitly disclose that
          QuizArena collects and uses your contact list information, should you opt to use the
          feature for connecting with friends within the app. This collection is done with your
          explicit consent and the data is used only for suggesting potential connections within
          QuizArena.
        </p>

        <h2>Collection and Use of Images</h2>
        <p>
          QuizArena collects images uploaded by users, such as profile pictures or images submitted
          for personalized quiz questions, with the explicit consent of our users. This consent is
          obtained through an in-app prompt that explains the purposes for which the images will be
          used, including profile customization and enhancing the quiz experience. We are committed
          to ensuring the security and privacy of these images, employing strict data protection
          measures to safeguard them from unauthorized access.
        </p>
        <p>
          Users have the right to manage or request the deletion of their images at any time. For
          assistance with managing your images, please contact us at playquizarena@gmail.com. We are
          dedicated to upholding the highest standards of privacy and data protection for our users.
        </p>

        <h2>Third-Party Code and SDKs</h2>
        <p>
          QuizArena may include third-party code (such as SDKs) in our app. We ensure that any
          third-party code used in our app, and the third party’s practices with respect to user
          data from our app, are compliant with Google Play Developer Program policies. This
          includes ensuring that our SDK providers do not sell personal and sensitive user data from
          our app. We are committed to transparency and compliance with all applicable privacy and
          data protection laws.
        </p>

        <h2>Changes to Our Privacy Policy</h2>
        <p>
          We reserve the right to modify this Privacy Policy at any time. Any changes will be
          effective immediately upon posting to this page. We encourage users to frequently check
          this page for any changes.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to
          contact us at playquizarena@gmail.com.
        </p>
      </div>
    </div>
  );
}
