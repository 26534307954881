import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DeepLinkHandler = () => {
  const location = useLocation();

  useEffect(() => {
    console.log('Current location:', location); // Debugging
    redirectToMobileAppIfNeeded(location);
    // eslint-disable-next-line
  }, [location]);

  const redirectToMobileAppIfNeeded = (location) => {
    const isMobile = checkIfMobileDevice();
    const deepLinkPath = getDeepLinkPath(location);

    console.log('Deep Link Path:', deepLinkPath); // Debugging
    if (isMobile && deepLinkPath) {
      window.location.href = deepLinkPath;
    }
  };

  const getDeepLinkPath = (location) => {
    // Implement the deep link path determination logic here
    if (location.pathname.startsWith('/invite')) {
      const id = location.search.split('=')[1];
      return `quizarena://--/invite?id=${id}`;
    }
    return null;
  };

  const checkIfMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  return null; // This component does not render anything
};

export default DeepLinkHandler;
